/* eslint-disable max-len */
const settings = {
    title: 'Impostazioni',
    tabs: {
        general: 'Generali',
        notification: 'Notifiche',
        notificationCampaign: 'Notifiche campagna',
        developers: 'Developers',
    },
    general: {
        personalization: 'Personalizzazione',
        selectImage: 'Scegli immagine',
        remove: 'Rimuovi',
        imageCaption: 'Dimesioni consigliate 212 x 32 pixel. Attenzione se la dimensione è differente l\'immagine verrà ridimensionata in modo da essere tutta visibile',
        logoError: 'Errore durante il salvataggio del logo',
        logoSuccess: 'Logo salvato con successo',
        personalizationError: 'Errore durante il salvataggio della personalizzazione',
        personalizationSuccess: 'Personalizzazione salvato con successo',
    },
    notification: {
        sectionTitle: 'Notifiche',
        sectionTooltip: 'Riceverai una notifica email 1 volta al giorno nel caso in cui attiverai la relativa notifica e la soglia impostata verrà raggiunta.',
        sectionDescription: 'Imposta uno o più notifiche email che definiranno i limiti di utilizzo della piattaforma in caso di:',
        creditEmailLimitText: 'Raggiungimento soglia di crediti email rimanenti',
        creditEmailLimitCaption: 'Riceverai una notifica email quando i tuoi crediti email raggiungeranno la soglia impostata.',
        limitLabel: 'Inserisci una soglia numerica',
        limitName: 'Soglia numerica',
        emailLabel: 'Inserisci uno o più indirizzi email a cui inviare la notifica',
        emailName: 'Email',
        addEmail: 'Aggiungi indirizzo email',
        creditSmsLimitText: 'Raggiungimento soglia di crediti sms rimanenti',
        creditSmsLimitCaption: 'Riceverai una notifica email quando i tuoi crediti sms raggiungeranno la soglia impostata.',
        contactsLimitText: 'Raggiungimento soglia numero di iscritti rispetto al piano',
        contactsLimitCaption: 'Riceverai una notifica email quando il numero dei tuoi iscritti raggiungerà la soglia impostata.',
        absolute: 'assoluto',
        percentage: 'percentuale',
        notificationSuccess: 'Notifiche salvate con successo',
        notificationError: 'Errore durante il salvataggio delle notifiche',
    },
    notificationCampaign: {
        title: 'Notifiche campagna',
    },
    senders: {
        favorites: 'Contatti preferiti',
        emailAuthorized: 'Mittenti autorizzati Email',
        smsAuthorized: 'Mittenti autorizzati Sms',
    },
    developers: {
        goalTracking: 'Goal tracking',
        goalTrackingDescription: 'Per attivare il Goal Tracking inserisci questo codice nell\'header del tuo sito o della landing page che vuoi monitorare.',
        copy: 'Copia codice',
        others: 'Altro',
        apikey: 'Api Key',
        integrations: 'Integrazioni',
    },
}
export default {
    settings,
}
